.persiePlayer {
  width: 100%;
  height: 100%;
}

.persieVideo {
  width: 100%;
  height: 100%;
}

.videoFill {
  object-fit: cover;
}

.videoFit {
  object-fit: contain;
}

.blackCover {
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.hideCover {
  display: none;
  visibility: hidden;
}
