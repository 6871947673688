.wrapper {
  /* Generated CSS from Studio uses absolute positions for zones. Set them relative to nearest parent element. */
  position: relative;
  width: 100%;
  height: 100%;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
