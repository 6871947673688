.base {
  height: 100%;
  position: absolute;
  width: 100%;
  visibility: hidden;
  z-index: -1;
}

.slotContainer {
  position: absolute;
  height: 100%;
  width: 100%;
}

.preloadDevPreview {
  position: absolute;
  visibility: visible;
  right: 0;
  bottom: 0;
  z-index: 1000;
  border: 5px solid #fd0b68;
  transform: scale(0.25) translate(150%, 150%);
}

.visible {
  visibility: visible;
  z-index: 1;
}

.tizenVideoPreload {
  visibility: unset;
  transform: scale(0.001);
  z-index: -1;
}
.display-none {
  display: none;
  opacity: 0;
}

.preloadCrossTransition {
  visibility: visible;
  z-index: 1;
  transform: none;
  border: none;
}

.activeViewer {
  z-index: 1;
  /* Fix safari issue when swapping from z-index:0 to z-index:1 */
  /* ref: https://stackoverflow.com/questions/43260816/elements-with-position-fixed-inside-an-iframe-are-not-rendering-in-safari/43435917#43435917 */
  transform: translateZ(0);
}
